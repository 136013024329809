import React, {useContext} from "react"
import SEO from "../components/seo"
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import PageBanner from '../components/pageBanner'
import {GlobalStateContext} from '../context/GlobalContextProvider'

export default function Terms({data, location}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {menu, terms} = state.language
    
    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title={menu[7].text} />
          <PageBanner>
            <h2>{terms.title}</h2>
            <section style={{marginTop:30}}>
              {
                terms.content.map((term, index)=>(
                  <section key={index}>
                    <h4>{term.title}</h4>
                    <div>{term.text.map((item, index)=>(<p key={index}>{item}</p>))}</div>
                  </section>
                ))
              }
            </section>
          </PageBanner>
        </LayoutMaster>
    )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`